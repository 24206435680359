import type { Vehicle } from '@/types/backend';

export const useVehicles = () => {
  const runtimeConfig = useRuntimeConfig();

  async function getAll() {
    const vehicles = useState<Vehicle[] | null>('vehicles', () => null);

    const { data, pending, error, refresh } = await useAsyncData<Vehicle[]>('vehicles', () =>
      $fetch(`${runtimeConfig.public.backendBaseUrl}/vehicles`),
    );
    vehicles.value = data.value;

    return {
      data: vehicles,
      pending,
      error,
      refresh,
    };
  }
  async function getFiltered(options: any) {
    const { data, pending, error, refresh } = await useLazyFetch<Vehicle[]>(
      `${runtimeConfig.public.backendBaseUrl}/vehicles`,
      {
        params: options,
        server: false,
      },
    );
    return {
      data,
      pending,
      error,
      refresh,
    };
  }

  interface GetVehicleOptions {
    ignoreBufferId?: string;
  }
  async function getSingle(id: string, options: GetVehicleOptions = {}) {
    const { data, pending, error, refresh } = await useAsyncData<Vehicle>(id, () =>
      $fetch(`${runtimeConfig.public.backendBaseUrl}/vehicles/${id}`, {
        params: options,
      }),
    );

    return {
      data,
      pending,
      error,
      refresh,
    };
  }

  return {
    getAll,
    getFiltered,
    getSingle,
  };
};
